import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Button } from 'app/components/Common/Button'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { theme } from 'app/theme'
import { useVocabularyData } from 'app/utils/vocabulary'
import { rgba } from 'emotion-rgba'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  address?: string
  googleMapsURL?: string
  image?: ImageProps
  languageCode: string
  siteName?: string
}

export const Map = memo(function Map({
  address,
  googleMapsURL,
  image,
  languageCode,
  siteName,
}: Props) {
  if (!image) {
    return null
  }

  return (
    <Container>
      <Head>
        {siteName ? (
          <FadeInUp>
            <SiteName className="wave-dark">{siteName}</SiteName>
          </FadeInUp>
        ) : null}

        {address ? (
          <FadeInUp>
            <Address dangerouslySetInnerHTML={{ __html: address }} />
          </FadeInUp>
        ) : null}
      </Head>

      <Background>
        <LazyLoadComponent>
          <Image {...image} />
        </LazyLoadComponent>

        {googleMapsURL ? (
          <CTA
            label={useVocabularyData('open-google-maps', languageCode)}
            rel="noopener"
            target="_blank"
            URL={googleMapsURL}
            variant="invert"
          />
        ) : null}
      </Background>
    </Container>
  )
})

const Container = styled.section``

const Head = styled.div`
  background: ${({ theme }) => theme.colors.variants.primaryLight1};
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  padding: 6rem 1.875rem;
  text-align: center;

  @media (max-width: 1199px) {
    padding: 3.75rem 1.875rem;
  }
`

const SiteName = styled.div`
  display: inline-block;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 4.375rem;
  line-height: 5rem;

  @media (max-width: 1199px) {
    font-size: 2.5rem;
    line-height: 2.8125rem;
    &::after {
      display: none !important;
    }
  }
`

const Address = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.1em;
  line-height: 1.125rem;
  margin-top: 1.875rem;
  text-transform: uppercase;
`

const Background = styled.div`
  aspect-ratio: 2.4;
  position: relative;
  &:after {
    content: '';
    width: 100%;
    height: 50%;
    background: linear-gradient(
      ${rgba(theme.colors.variants.primaryDark1, 0)},
      ${rgba(theme.colors.variants.primaryDark1, 0.6)}
    );
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 1023px) {
    aspect-ratio: 1;
  }
`

const CTA = styled(Button)`
  position: absolute;
  bottom: 4.5rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;

  @media (max-width: 1199px) {
    bottom: 1.875rem;
  }
`
