import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'
import { Accordion } from 'react-accessible-accordion'

import { Item, Props as ItemProps } from './Item'

export interface Props {
  items: ItemProps[]
  languageCode: string
}

export const Directions = memo(function Directions({
  items,
  languageCode,
}: Props) {
  if (!items) {
    return null
  }

  return (
    <Container className="blended" id="directions">
      <FadeInUp>
        <Title className="wave">
          {useVocabularyData('how-to-reach', languageCode)}
        </Title>
      </FadeInUp>

      <FadeInUp>
        <Accordion allowZeroExpanded>
          {items.map((item, index) => (
            <Item key={index} {...item} />
          ))}
        </Accordion>
      </FadeInUp>
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  padding: 9rem 12.639vw 10.8125rem;

  .accordion {
    > div {
      &.active {
        background: ${({ theme }) => theme.colors.variants.neutralLight4};
        border: 0.0625rem solid transparent;
        margin-top: 0.75rem;
      }
    }
  }

  @media (max-width: 1199px) {
    padding: 5.625rem 1.875rem;
  }
`

const Title = styled.h2`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.variants.primaryDark3};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5rem;
  line-height: 2.8125rem;
  margin-bottom: 2.5rem;
  &:after {
    flex: 1;
    margin: 0 0 0 3.75rem;
  }

  @media (max-width: 1199px) {
    &::after {
      display: none !important;
    }
  }
`
