import styled from '@emotion/styled'
import { Minus, Plus } from 'app/components/Icons'
import { AnimatePresence, motion } from 'framer-motion'
import React, { memo } from 'react'
import {
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemState,
} from 'react-accessible-accordion'

export interface Props {
  description?: string
  title?: string
}

export const Item = memo(function Item({ description, title }: Props) {
  if (!title) {
    return null
  }

  return (
    <Container activeClassName="active">
      <Head>
        <Title>
          <Inner>{title}</Inner>
          <Wrap>
            <State>{({ expanded }) => (expanded ? <Minus /> : <Plus />)}</State>
          </Wrap>
        </Title>
      </Head>

      {description ? (
        <Panel>
          <State>
            {({ expanded }) => (
              <AnimatePresence>
                {expanded && (
                  <motion.div
                    initial={{ opacity: 0, height: 0 }}
                    animate={{
                      opacity: 1,
                      height: 'auto',
                    }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{ duration: 0.4 }}
                  >
                    <Description
                      dangerouslySetInnerHTML={{ __html: description }}
                    />
                  </motion.div>
                )}
              </AnimatePresence>
            )}
          </State>
        </Panel>
      ) : null}
    </Container>
  )
})

const Container = styled(AccordionItem)`
  border: 0.0625rem solid ${({ theme }) => theme.colors.variants.neutralLight2};
  margin-top: 0.75rem;
  transition: 0.3s cubic-bezier(0, 0.55, 0.45, 1);
`

const Head = styled(AccordionItemHeading)`
  cursor: pointer;
`

const Title = styled(AccordionItemButton)`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.variants.primaryDark3};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.8125rem;
  font-weight: 500;
  letter-spacing: 0.1em;
  line-height: 1.125rem;
  padding: 1.3125rem 1.875rem;
  text-transform: uppercase;
`

const Inner = styled.div`
  width: calc(100% - 1.875rem);
`

const Wrap = styled.div`
  margin-left: auto;

  svg {
    width: auto;
    height: 0.75rem;
    stroke: ${({ theme }) => theme.colors.variants.primaryDark3};
  }
`

const State = styled(AccordionItemState)``

const Panel = styled(AccordionItemPanel)`
  display: block !important;
  color: ${({ theme }) => theme.colors.variants.neutralDark1};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.0625rem;
  line-height: 2rem;
  overflow: hidden;
  padding: 0 1.875rem;
`

const Description = styled.div`
  padding-bottom: 1.375rem;
`
